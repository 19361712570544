import { ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#2f8bbd',
    },
    secondary: {
      main: '#f50057',
    },
    header: {
      main: '#000000',
    },
  },
  compeonLogo: {
    color: '#ffffff',
  },
  chonky: {
    colors: {
      debugRed: '#fabdbd',
      debugBlue: '#bdd8fa',
      debugGreen: '#d2fabd',
      debugPurple: '#d2bdfa',
      debugYellow: '#fae9bd',
      datacenterBlue: '#09f',

      textActive: '#09f',
    },
    fontSizes: {
      rootPrimary: 15,
    },
    margins: {
      rootLayoutMargin: 8,
    },
    toolbar: {
      size: 30,
      lineHeight: '30px', // `px` suffix is required for `line-height` fields to work
      fontSize: 15,
      buttonRadius: 4,
    },
    dnd: {
      canDropColor: 'green',
      cannotDropColor: 'red',
      canDropMask: 'rgba(180, 235, 180, 0.75)',
      cannotDropMask: 'rgba(235, 180, 180, 0.75)',
      fileListCanDropMaskOne: 'rgba(180, 235, 180, 0.1)',
      fileListCanDropMaskTwo: 'rgba(180, 235, 180, 0.2)',
      fileListCannotDropMaskOne: 'rgba(235, 180, 180, 0.1)',
      fileListCannotDropMaskTwo: 'rgba(235, 180, 180, 0.2)',
    },
    dragLayer: {
      border: 'solid 2px #09f',
      padding: '7px 10px',
      borderRadius: 2,
    },
    fileList: {
      desktopGridGutter: 8,
      mobileGridGutter: 5,
    },
    gridFileEntry: {
      childrenCountSize: '1.6em',
      iconColorFocused: '#000',
      iconSize: '2.4em',
      iconColor: '#fff',
      borderRadius: 1.25,
      fontSize: 14,

      fileColorTint: 'rgba(50, 50, 50, 0.4)',
      folderBackColorTint: 'rgba(50, 50, 50, 0.4)',
      folderFrontColorTint: 'rgba(50, 50, 50, 0.15)',
    },
    listFileEntry: {
      propertyFontSize: 14,
      iconFontSize: '1.1em',
      iconBorderRadius: 5,
      fontSize: 14,
      propertyHeaderFontSize: 12,
    },
  },
};

export default themeOptions;
